import './App.css';
import sakthiSj from './Images/Sakthi.png'
import EmailIcon from '@mui/icons-material/Email';
import XIcon from '@mui/icons-material/X';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import GestureIcon from '@mui/icons-material/Gesture';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import YouTubeIcon from '@mui/icons-material/YouTube';
import { Typography, Box } from '@mui/material';



function App() {
  return (

    <Box display='flex' justifyContent='center' alignItems='center' flexDirection='column' sx={{ my: 5}}>
      <img src={sakthiSj} alt='Sakthi SJ' />
      <Typography variant='h3'>
        Hello, I am Sakthivel Sigamani
        <br/>
          <span> <EmailIcon fontSize='large' sx={{ mx: '2' }} /><a href='mailto:contact@sakthivelsj.com'> contact@sakthivelsj.com </a> </span>
      </Typography>

      <div className='icon-container'>
        <a href='https://twitter.com/SakthivelSJ18' target='_blank' rel="noopener noreferrer"> <XIcon /></a>
        <a href='https://www.facebook.com/SakthivelSJ18/' target='_blank' rel="noopener noreferrer"> <FacebookIcon /></a>
        <a href='https://www.instagram.com/sakthivelsj18/' target='_blank' rel="noopener noreferrer"> <InstagramIcon /></a>
        <a href='https://www.threads.net/@sakthivelsj18' target='_blank' rel="noopener noreferrer"> <GestureIcon /></a>
        <a href='https://www.linkedin.com/in/sakthivel-sigamani-8996a118b/' > <LinkedInIcon /></a>
        <a href='https://www.youtube.com/@SakthivelSJ18' target='_blank' rel="noopener noreferrer"> <YouTubeIcon /></a>

      </div>

    </Box>
    
  );
}

export default App;
